@import "normalize.css/normalize.css";
/* pt-sans-regular - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: local('PT Sans'), local('PTSans-Regular'),
       url('../fonts/pt-sans-v9-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/pt-sans-v9-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* pt-serif-regular - latin */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  src: local('PT Serif'), local('PTSerif-Regular'),
       url('../fonts/pt-serif-v9-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/pt-serif-v9-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* pt-mono-regular - latin */
@font-face {
  font-family: 'PT Mono';
  font-style: normal;
  font-weight: 400;
  src: local('PT Mono'), local('PTMono-Regular'),
       url('../fonts/pt-mono-v5-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/pt-mono-v5-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #4d4d4d;
  font-family: 'PT Sans', sans-serif;
  font-size: 18px;
}
a {
  color: #000000;
  text-decoration: none;
}
hr {
  color: #eeeeee;
  background-color: #eeeeee;
  height: 1px;
  border: none;
}
img {
  max-width: 100%;
}
.round-img {
  border-radius: 50%;
  padding: 2px;
  border: 10px solid;
}
aside div#user_meta {
  padding: 40px;
  text-align: center;
}
aside div#user_meta h2 {
  margin: 15px 0 5px 0;
}
aside div#user_meta p {
  margin: 0 0 15px 0;
  font-size: 0.8rem;
}
aside div#user_meta ul {
  margin: 40px 0 0 0;
  padding-left: 0;
  font-size: 0.9rem;
  list-style-type: none;
}
aside div#user_meta ul li {
  display: inline;
  line-height: 3em;
}
aside div#user_meta ul li a {
  padding: 5px 15px 5px 15px;
  border: 3px solid #eeeeee;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  color: #999999;
}
main header {
  padding: 40px;
  border-top: #eeeeee 1px solid;
  border-bottom: #eeeeee 1px solid;
}
main header p {
  font-weight: bold;
  font-size: 0.6rem;
  text-transform: uppercase;
}
main article {
  max-width: 640px;
  padding: 40px;
}
main article div.article_text {
  line-height: 1.375em;
}
main article div.article_text a {
  text-decoration: underline;
}
main article div.article_text code {
  margin: 2px;
  padding: 0;
  color: #000000;
  font-size: 0.8rem;
  font-family: 'PT Mono', monospace;
  background: #eeeeee;
}
main article div.article_text pre {
  margin: 22px 2px 22px 2px;
  padding: 2px;
  color: #000000;
  border-left: 3px solid #0e94ec;
  font-size: 0.8rem;
  font-family: 'PT Mono', monospace;
  background: #eeeeee;
  line-height: 1.125em;
  overflow-x: auto;
}
main article div.article_text blockquote {
  margin: 22px 2px 22px 2px;
  padding-left: 40px;
  color: #999999;
  font-style: italic;
}
main article div.gist {
  line-height: 0.875em;
}
main article div.article_meta {
  font-size: 0.7rem;
  color: #999999;
}
main footer {
  border-top: #eeeeee 1px solid;
  padding: 40px;
}
main footer a.button_accent {
  padding: 10px;
  border: 2px solid #0e94ec;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  text-transform: uppercase;
  color: #0e94ec;
  font-size: 1rem;
}
main footer a.button_accent:hover {
  color: #ffffff;
  background-color: #0e94ec;
}
main footer a.button_accent:active {
  color: #ffffff;
  background-color: #0e94ec;
}
main div#ending_message {
  border-top: #eeeeee 1px solid;
  padding: 0 40px 0 40px;
  color: #999999;
  font-size: 0.6rem;
}
@media screen and (min-width: 1024px) {
  aside {
    width: 25%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border-right: #eeeeee 1px solid;
  }
  aside h2 {
    margin: 10px 0 0 0;
  }
  aside div#user_meta {
    max-width: 192px;
    text-align: right;
  }
  aside div#user_meta ul li {
    display: block;
  }
  main {
    width: 75%;
    position: absolute;
    top: 0;
    left: 25%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  main article {
    max-width: 640px;
  }
  main header {
    border-top: 0;
  }
}
.hll { background-color: #ffffcc }
.c { color: #408080; font-style: italic } /* Comment */
.err { border: 1px solid #FF0000 } /* Error */
.k { color: #008000; font-weight: bold } /* Keyword */
.o { color: #666666 } /* Operator */
.cm { color: #408080; font-style: italic } /* Comment.Multiline */
.cp { color: #BC7A00 } /* Comment.Preproc */
.c1 { color: #408080; font-style: italic } /* Comment.Single */
.cs { color: #408080; font-style: italic } /* Comment.Special */
.gd { color: #A00000 } /* Generic.Deleted */
.ge { font-style: italic } /* Generic.Emph */
.gr { color: #FF0000 } /* Generic.Error */
.gh { color: #000080; font-weight: bold } /* Generic.Heading */
.gi { color: #00A000 } /* Generic.Inserted */
.go { color: #888888 } /* Generic.Output */
.gp { color: #000080; font-weight: bold } /* Generic.Prompt */
.gs { font-weight: bold } /* Generic.Strong */
.gu { color: #800080; font-weight: bold } /* Generic.Subheading */
.gt { color: #0044DD } /* Generic.Traceback */
.kc { color: #008000; font-weight: bold } /* Keyword.Constant */
.kd { color: #008000; font-weight: bold } /* Keyword.Declaration */
.kn { color: #008000; font-weight: bold } /* Keyword.Namespace */
.kp { color: #008000 } /* Keyword.Pseudo */
.kr { color: #008000; font-weight: bold } /* Keyword.Reserved */
.kt { color: #B00040 } /* Keyword.Type */
.m { color: #666666 } /* Literal.Number */
.s { color: #BA2121 } /* Literal.String */
.na { color: #7D9029 } /* Name.Attribute */
.nb { color: #008000 } /* Name.Builtin */
.nc { color: #0000FF; font-weight: bold } /* Name.Class */
.no { color: #880000 } /* Name.Constant */
.nd { color: #AA22FF } /* Name.Decorator */
.ni { color: #999999; font-weight: bold } /* Name.Entity */
.ne { color: #D2413A; font-weight: bold } /* Name.Exception */
.nf { color: #0000FF } /* Name.Function */
.nl { color: #A0A000 } /* Name.Label */
.nn { color: #0000FF; font-weight: bold } /* Name.Namespace */
.nt { color: #008000; font-weight: bold } /* Name.Tag */
.nv { color: #19177C } /* Name.Variable */
.ow { color: #AA22FF; font-weight: bold } /* Operator.Word */
.w { color: #bbbbbb } /* Text.Whitespace */
.mf { color: #666666 } /* Literal.Number.Float */
.mh { color: #666666 } /* Literal.Number.Hex */
.mi { color: #666666 } /* Literal.Number.Integer */
.mo { color: #666666 } /* Literal.Number.Oct */
.sb { color: #BA2121 } /* Literal.String.Backtick */
.sc { color: #BA2121 } /* Literal.String.Char */
.sd { color: #BA2121; font-style: italic } /* Literal.String.Doc */
.s2 { color: #BA2121 } /* Literal.String.Double */
.se { color: #BB6622; font-weight: bold } /* Literal.String.Escape */
.sh { color: #BA2121 } /* Literal.String.Heredoc */
.si { color: #BB6688; font-weight: bold } /* Literal.String.Interpol */
.sx { color: #008000 } /* Literal.String.Other */
.sr { color: #BB6688 } /* Literal.String.Regex */
.s1 { color: #BA2121 } /* Literal.String.Single */
.ss { color: #19177C } /* Literal.String.Symbol */
.bp { color: #008000 } /* Name.Builtin.Pseudo */
.vc { color: #19177C } /* Name.Variable.Class */
.vg { color: #19177C } /* Name.Variable.Global */
.vi { color: #19177C } /* Name.Variable.Instance */
.il { color: #666666 } /* Literal.Number.Integer.Long */

#user_meta a img {
  height: 140px;
  width: 140px;
}
