@red:  #ff0000;
@black:  #000000;
@white:  #ffffff;
@light-grey:  #eeeeee;
@med-grey:  #999999;
@dark-grey:  #4d4d4d;
@blue:  #0e94ec;
@accent:  @blue;
@sans: 'PT Sans', sans-serif;
@serif: 'PT Serif', serif;
@mono: 'PT Mono', monospace;

body {
  margin: 0;
  padding: 0;
  background-color: @white;
  color: @dark-grey;
  font-family: @sans;
  font-size: 18px;
}

a {
  color: @black;
  text-decoration: none;
}

hr {
  color: @light-grey;
  background-color: @light-grey;
  height: 1px;
  border: none;
}

.border-radius (@radius: 5px) {
  border-radius: @radius;
  -moz-border-radius: @radius;
  -webkit-border-radius: @radius;
}

img {
  max-width: 100%;
}

.round-img {
  border-radius: 50%;
  padding: 2px;
  border: 10px solid;
}

aside {
  div#user_meta {
    padding: 40px;
    text-align: center;

    h2 {
      margin: 15px 0 5px 0;
    }

    p {
      margin: 0 0 15px 0;
      font-size: .8rem;
    }

    ul {
      margin: 40px 0 0 0;
      padding-left: 0;
      font-size: .9rem;
      list-style-type: none;

      li {
        display: inline;
        line-height: 3em;

        a {
          padding: 5px 15px 5px 15px;
          border: 3px solid @light-grey;
          .border-radius(15px);
          color: @med-grey;
        }
      }
    }
  }
}

main {
  header {
    padding: 40px;
    border-top: @light-grey 1px solid;
    border-bottom: @light-grey 1px solid;

    p {
      font-weight: bold;
      font-size: .6rem;
      text-transform: uppercase;
    }
  }

  article {
    max-width: 640px;
    padding: 40px;

    div.article_title {
    }

    div.article_text {
      line-height: 1.375em;
      a {
        text-decoration: underline;
      }

      code {
        margin: 2px;
        padding: 0;
        color: @black;
        font-size: .8rem;
        font-family: @mono;
        background: @light-grey;
      }

      pre {
        margin: 22px 2px 22px 2px;
        padding: 2px;
        color: @black;
        border-left: 3px solid @accent;
        font-size: .8rem;
        font-family: @mono;
        background: @light-grey;
        line-height: 1.125em;
        overflow-x: auto;
      }

      blockquote {
        margin: 22px 2px 22px 2px;
        padding-left: 40px;
        color: @med-grey;
        font-style: italic;
      }
    }

    div.gist {
      line-height: .875em;
    }

    div.article_meta {
      font-size: .7rem;
      color: @med-grey;
    }
  }

  footer {
    border-top: @light-grey 1px solid;
    padding: 40px;

    a.button_accent {
      padding: 10px;
      border: 2px solid @accent;
      .border-radius(20px);
      text-transform: uppercase;
      color: @accent;
      font-size: 1rem;
      &:hover {
        color: @white;
        background-color: @accent;
      }
      &:active {
        color: @white;
        background-color: @accent;
      }
    }

    div#paginator {
    }
  }

  div#ending_message {
    border-top: @light-grey 1px solid;
    padding: 0 40px 0 40px;
    color: @med-grey;
    font-size: .6rem;
  }
}

@media screen and (min-width: 1024px) {
  aside {
    width: 25%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border-right: @light-grey 1px solid;

    h2 {
      margin: 10px 0 0 0;
    }

    div#user_meta {
      max-width: 192px;
      text-align: right;

      ul {
        li {
          display: block;
        }
      }
    }
  }

  main {
    width: 75%;
    position: absolute;
    top: 0;
    left: 25%;
    overflow-x: hidden;
    overflow-y: hidden;

    article {
      max-width: 640px;
    }

    header {
      border-top: 0;
    }
  }
}
